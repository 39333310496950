import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { environment } from 'webapp/environments/environment';
import { Action } from 'webapp/app/shared/ga-datatable-new/model/action.model';
import { ColumnType } from 'webapp/app/shared/ga-datatable-new/model/datatable.model';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class CertificateListService {
	constructor(private http: HttpClient, private i18n: TranslocoService) {}
	organization = 0;

	getCertificate(id: number): Observable<any> {
		return this.http.get(`${environment.backendUrl}/api/certificate/${id}`);
	}

	listCertificates(params: any = {}): Observable<any> {
		if (params.organization) this.organization = params.organization;
		return this.http.get(`${environment.backendUrl}/api/certificate`, {
			params: params,
		});
	}
	createCertificate(body: any = {}): Observable<any> {
		return this.http.post(`${environment.backendUrl}/api/certificate/`, body);
	}

	updateCertificate(id: number, body: any = {}): Observable<any> {
		return this.http.put(`${environment.backendUrl}/api/certificate/${id}`, body);
	}

	deleteCertificate(id: number): Observable<any> {
		return this.http.delete(`${environment.backendUrl}/api/certificate/${id}`);
	}

	renderListToTable(certificates) {
		return certificates.map((cert) => {
			const row = {} as any;

			row.name = row.name = cert.name;
			row.user = this.renderName(cert.user);
			row.submissionDate = cert.submissionDate ? moment(cert.submissionDate).format('DD/MM/YYYY') : '';
			row.expirationDate = this.renderExpirationDate(cert.expirationDate);
			row.action = this.getActions(cert);
			return row;
		});
	}

	getActions(certificate): Action[] {
		const actions: Action[] = [
			{
				icon: 'fa-edit',
				title: this.i18n.translate('global.edit'),
				type: 'edit',
				permission: 'certificationCreate',
				row: certificate,
			},
			{
				icon: 'fa-trash',
				title: this.i18n.translate('global.delete'),
				type: 'delete',
				permission: 'certificationUpdate',
				row: certificate,
			},
		];

		return actions;
	}

	renderName(user) {
		return `
			<div style="display: flex; flex-direction: column">
				<span style="font-weight: 700">${user ? user.name : ''}</span>
				<small>${user ? user.email : ''}</small>
			</div>
		  `;
	}

	renderExpirationDate(date) {
		if (!date) return '';
		const expirationDate = moment(date);
		const expired = moment().isAfter(expirationDate);
		const secondLine = this.i18n.translate('global.expired');

		if (!expired) {
			return expirationDate.format('DD/MM/YYYY');
		} else {
			return `
          <span class="error-field">
            <div>${expirationDate.format('DD/MM/YYYY')}</div>
            <div>${secondLine}</div>
          </span>
      `;
		}
	}

	get columns(): ColumnType[] {
		return [
			{
				label: this.i18n.translate('certification.table.name'),
				name: 'name',
				type: 'text',
				width: '30%',
			},
			{
				label: this.i18n.translate('certification.table.send-by'),
				name: 'user',
				type: 'custom',
				width: '30%',
			},
			{
				label: this.i18n.translate('certification.table.send-date'),
				name: 'submissionDate',
				type: 'text',
				width: '20%',
			},
			{
				label: this.i18n.translate('certification.table.expiration-date'),
				name: 'expirationDate',
				type: 'custom',
				width: '20%',
			},
			{
				label: '',
				name: 'action',
				type: 'action',
				width: '45px',
			},
		];
	}
}
