import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { TranslocoService } from '@ngneat/transloco';
import { StateParams, StateService } from 'angular-ui-router';
import { EMPTY, Subscription, from } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { UserOrganizationRole } from 'webapp/app/shared/models/organization.model';
import { ActionButton, SelectorGroup, SelectorOption } from 'webapp/app/shared/models/selector.model';
import { User } from 'webapp/app/shared/models/user.model';
import { DataSourceService } from 'webapp/app/shared/services/datasource.service';
import { OrganizationService } from 'webapp/app/shared/services/organization.service';
import { UserService } from 'webapp/app/shared/services/user.service';
import { NG_ROUTER, NG_STATE_PARAMS } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { GaOrganizationProfilesComponent } from '../ga-organization-profiles/ga-organization-profiles.component';
import { UserDatasource } from 'webapp/app/shared/models/datasource.model';
import { Meter } from 'webapp/app/shared/models/meter.model';
import { NotificationService } from 'webapp/app/shared/notification/notification.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
	GaContentDialogComponent,
	GaContentDialogModel,
} from 'webapp/app/shared/ga-dialog/ga-content-dialog.component';
import moment from 'moment';

@Component({
	selector: 'user-details',
	templateUrl: './user-details.component.html',
	styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit, OnDestroy {
	@ViewChild(GaOrganizationProfilesComponent)
		profilesComponent!: GaOrganizationProfilesComponent;
	@ViewChild('inviteSucessTemplate')
		inviteSucessTemplate!: TemplateRef<any>;
	dialogRef!: MatDialogRef<any>;
	organization: number | undefined;
	user: User = {} as User;
	currentUser: User = {} as User;
	userForm!: FormGroup;
	subscriptions: Subscription[] = [];
	currentUserRoleOrgs: UserOrganizationRole[] = [];
	userRoleOrgs: UserOrganizationRole[] = [];
	panelOptions: SelectorOption[] = [];
	connectionOptions: SelectorOption[] = [];
	allDataSources: Array<Meter> = [];
	groupDataSources: Array<SelectorGroup> = [];
	userDataSources: UserDatasource[] = [];
	actionButtons: ActionButton[] = [];
	isSaving = false;
	formattedDate = new FormControl();

	constructor(
		@Inject(NG_ROUTER) private $state: StateService,
		@Inject(NG_STATE_PARAMS) private $stateParams: StateParams,
		private organizationService: OrganizationService,
		private dataSourceService: DataSourceService,
		private notification: NotificationService,
		private userService: UserService,
		private i18n: TranslocoService,
		public dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.initFormGroup();
		this.loadDataSources();
		this.initUser();
		this.loadCurrentUser();
		this.loadBillingPlans();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription?.unsubscribe());
	}
	// ------------------------- Init functions
	initFormGroup() {
		const group: any = {
			firstName: new FormControl(''),
			lastName: new FormControl(''),
			email: new FormControl('', Validators.email),
			billingplan: new FormControl(''),
			accountExpiration: new FormControl(''),
			dataSources: new FormControl(''),
			offlineReport: new FormControl(true),
			organization: new FormControl('', Validators.required),
		};

		this.userForm = new FormGroup(group);
	}

	loadBillingPlans() {
		const subscription = from(this.organizationService.getBillingplans())
			.pipe(
				tap((billingPlans) => {
					const expertPlan = billingPlans.find((plan) => plan.name === 'comercial');
					if (expertPlan)
						this.panelOptions.push({
							id: expertPlan.id,
							label: this.i18n.translate('user-invite.control.panel.expert'),
							subtext: this.i18n.translate('user-invite.tooltip.expert'),
						});
					const simplifiedPlan = billingPlans.find((plan) => plan.name === 'residencial');
					if (simplifiedPlan)
						this.panelOptions.push({
							id: simplifiedPlan.id,
							label: this.i18n.translate('user-invite.control.panel.simple'),
							subtext: this.i18n.translate('user-invite.tooltip.simple'),
						});
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	loadCurrentUser() {
		const subscription = from(this.userService.getCurrentUser())
			.pipe(
				tap((user) => (this.currentUser = user)),
				switchMap((user) => {
					return from(this.organizationService.getUserRoleOrganizations(user.id));
				}),
				tap((currentUserRoleOrgs) => {
					if (!currentUserRoleOrgs) return;
					const currentRole = currentUserRoleOrgs.reduce((acc, val) => {
						if (!acc || !Array.isArray(acc) || acc.length === 0) return [acc, val];
						const org = acc.find((role) => role.organization.id === val.organization.id);
						if (org) return acc;
						return acc.concat([val]);
					});
					this.currentUserRoleOrgs = Array.isArray(currentRole) ? currentRole : [currentRole];
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	initUser() {
		this.user.id = this.$stateParams.id;

		if (!this.isEdition()) {
			return;
		}

		const subscription = from(this.userService.getUser(this.user.id))
			.pipe(
				tap((user) => {
					this.user = user;
					Object.keys(user).forEach((key) => {
						if (this.userForm.controls[key] && user[key]) this.userForm.controls[key].setValue(user[key]);
					});

					const date = this.userForm.value.accountExpiration
						? moment(this.userForm.value.accountExpiration).utc().format('DD/MM/YYYY')
						: null;
					this.formattedDate.setValue(date);
				}),
				switchMap((user) => {
					// this.loadDataSourcesOfUser();
					this.loadUserDatasources();
					return from(this.organizationService.getUserRoleOrganizations(user.id));
				}),
				tap((userRoleOrgs) => (this.userRoleOrgs = userRoleOrgs))
			)
			.subscribe();

		this.subscriptions.push(subscription);
	}

	loadDataSourcesOfUser() {
		if (!this.isEdition()) return;
		const subscription = from(this.dataSourceService.getDataSourcesOfUser(this.user.id))
			.pipe(
				tap((userDataSources: any) => {
					const dataSources = userDataSources.map((dataSource) => dataSource.id);
					this.userForm.controls['dataSources'].setValue(dataSources);
					this.updateDatasources(this.userRoleOrgs.map((roleOrg) => roleOrg.organization.id));
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	loadUserDatasources() {
		const subscriptionUDS = from(this.dataSourceService.getUserDataSources(this.user.id))
			.pipe(
				tap((userDataSources: UserDatasource[]) => {
					this.userDataSources = userDataSources.filter((uds) => uds.dataSource.type === 'meter');
					const dataSources = userDataSources.map((userDataSource) => userDataSource.dataSource.id);
					this.userForm.controls['dataSources'].setValue(dataSources);
					this.updateDatasources(this.userRoleOrgs.map((roleOrg) => roleOrg.organization.id));
				})
			)
			.subscribe();
		this.subscriptions.push(subscriptionUDS);
	}

	updateDatasources(arrValue) {
		const filteredSources = this.allDataSources.filter((ds) => arrValue.includes(ds.organizationId));
		this.groupDataSources = this.selectorGroupFactory(filteredSources);
		this.connectionOptions = [
			{
				id: true,
				label: this.i18n.translate('user-invite.receive-alerts.true'),
			},
			{
				id: false,
				label: this.i18n.translate('user-invite.receive-alerts.false'),
			},
		];
	}

	updateMainOrg(event) {
		this.userForm.controls['organization'].setValue(event);
	}

	loadDataSources() {
		const subscription = from(this.dataSourceService.getDataSources())
			.pipe(
				tap((dataSources: Meter[]) => (this.allDataSources = dataSources)),
				tap(() => this.updateDatasources(this.userRoleOrgs.map((roleOrg) => roleOrg.organization.id)))
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	// Config Functions

	isSelfEdit() {
		const isSelfEdit = this.currentUser.id == this.user.id;
		return this.isEdition() && (this.currentUser.isAdmin || isSelfEdit);
	}

	isEdition() {
		return !!this.user.id;
	}

	selectorGroupFactory(arr: Array<any>) {
		const group: SelectorGroup[] = [];
		arr.forEach((element) => {
			const foundOrg = group.find((org) => org.id == element.organizationId);

			if (foundOrg && foundOrg.options) {
				foundOrg.options.push({
					id: element.id,
					label: element.label,
					icon: element.uid ? 'fa-circle-bolt' : 'fa-draw-circle',
					subtext: element.uid ? `${this.i18n.translate('global.code')}: ${element.uid}` : '',
				});
			} else {
				group.push({
					label: element.organizationName as string,
					id: element.organizationId as number,
					disabled: false,
					options: [
						{
							id: element.id,
							label: element.label,
							icon: element.uid ? 'fa-circle-bolt' : 'fa-draw-circle',
							subtext: element.uid ? `${this.i18n.translate('global.code')}: ${element.uid}` : '',
						},
					],
				});
			}
		});

		return group.sort((a, b) => this.compareDesc(a, b, 'name'));
	}

	compareDesc(a, b, field) {
		if (a[field] > b[field]) {
			return -1;
		}
		if (a[field] < b[field]) {
			return 1;
		}
		return 0;
	}

	backTo() {
		this.$state.transitionTo(
			'registrations',
			{ tab: 'users', organizationId: this.organization },
			{
				inherit: false,
				reload: false,
				notify: false,
			}
		);
	}

	onSubmit() {
		const data = {
			dataSources: this.userForm.controls['dataSources'].value,
			offlineReport: this.userForm.controls['offlineReport'].value,
			roleOrgs: this.profilesComponent.dataTable,
			user: {
				...this.user,
				firstName: this.userForm.controls['firstName'].value,
				lastName: this.userForm.controls['lastName'].value,
				email: this.userForm.controls['email'].value,
				billingplan: this.userForm.controls['billingplan'].value,
				accountExpiration: this.userForm.controls['accountExpiration'].value?.start || null,
				organization: this.userForm.controls['organization'].value,
			},
		};

		if (this.isEdition()) {
			this.editUser(data);
		} else {
			this.inviteUser(data);
		}
	}

	inviteUser(body) {
		delete body.user.id;
		const subscription = from(this.userService.invite(body))
			.pipe(
				tap(() => this.showModal()),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return EMPTY;
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	showModal() {
		this.actionButtons = [
			{
				title: this.i18n.translate('user-invite.modal.opt.preserve.fields'),
				actionName: 'same',
			},
			{
				title: this.i18n.translate('user-invite.modal.opt.clean.form'),
				actionName: 'new',
			},
		];

		const dialogModel: GaContentDialogModel = {} as GaContentDialogModel;
		dialogModel.icon = 'success';
		dialogModel.iconType = 'success';
		dialogModel.title = this.i18n.translate('user-invite.modal.invite.success');
		dialogModel.message = `${this.i18n.translate('user-invite.modal.sent.to')} ${
			this.userForm.controls['email'].value
		}`;
		dialogModel.template = this.inviteSucessTemplate;
		dialogModel.showButtons = false;
		this.dialogRef = this.dialog.open(GaContentDialogComponent, {
			disableClose: true,
			data: dialogModel,
			width: '402px',
		});
	}

	editUser(body) {
		const subscription = from(this.userService.saveUser(this.user.id, body))
			.pipe(
				tap(() => {
					this.notification.success({
						msg: this.i18n.translate('user-invite.edit.success'),
					});
					this.backTo();
				}),
				catchError((error) => {
					this.notification.showErrorMessages(error);
					return EMPTY;
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	closeDialog() {
		this.dialogRef.close();
	}

	newAction(event) {
		switch (true) {
		case event.actionName === 'same':
			this.userForm.controls['firstName'].setValue('');
			this.userForm.controls['lastName'].setValue('');
			this.userForm.controls['email'].setValue('');
			break;
		case event.actionName === 'new':
			Object.keys(this.userForm.value).forEach((key) => {
				if (key === 'offlineReport') this.userForm.controls[key].setValue(true);
				else this.userForm.controls[key].setValue('');
			});
			this.userDataSources = [];
			this.userRoleOrgs = [];
			this.user = {} as User;
			break;
		default:
			break;
		}
		this.closeDialog();
	}
}

export const ng2UserDetailsComponent = {
	name: 'userDetails',
	def: downgradeComponent({
		component: UserDetailsComponent,
		propagateDigest: true,
	}),
};
