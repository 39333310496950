<div class="content-wrapper view">
	<div class="container-fluid">
		<!-- ------------------------- Navbar Module ------------------------- -->
		<nav class="ga-navbar">
			<div class="navbar-content">
				<h3>
					<a (click)="backTo()">{{ 'main.menu.registrations' | transloco }}</a>
					<i class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"></i>
					<a (click)="backTo()"> {{ 'main.menu.points' | transloco }} </a>
					<i class="fa-regular fa-chevron-right navbar-content__breadcrumb-icon"></i>
					<span *ngIf="!groupId" class="navbar-content__breadcrumb-page">
						{{ 'group-page.description.new-group-title' | transloco }}
					</span>
					<span *ngIf="groupId" class="navbar-content__breadcrumb-page">
						{{ groupName }}
					</span>
				</h3>
			</div>
		</nav>

		<!-- ------------------------- Title description of page ------------------------- -->
		<div class="description">
			<div class="description__texts">
				<span class="description__texts__title">{{
					!groupId
						? ('group-page.description.new-group-title' | transloco)
						: ('group-page.description.edit-title' | transloco)
				}}</span>
				<span class="description__texts__subtitle">{{
					!groupId
						? ('group-page.description.new-group-subtitle' | transloco)
						: ('group-page.description.edit-subtitle' | transloco)
				}}</span>
			</div>
		</div>

		<!-- ------------------------- Form Module ------------------------- -->
		<form id="groupForm" [formGroup]="groupForm" class="flex-col" (ngSubmit)="onSubmit()" focusInvalidInput>
			<!-- ------------------------- Basic Data Module ------------------------- -->
			<div id="basicData" class="row col-lg-12 module-group">
				<h3 class="basic-data__title col-lg-12">
					{{ 'global.basic-info' | transloco }}
				</h3>
				<div class="row col-lg-12">
					<!-- Organização -->
					<div class="form-group col-lg-6">
						<autocomplete-orgs
							[isEnabled]="!groupId"
							[idSelected]="organization"
							(orgEmitter)="orgSelected($event)"
							[isRequired]="true"
						></autocomplete-orgs>
					</div>

					<!-- Empresa -->
					<div class="form-group col-lg-6">
						<autocomplete-companies
							[idSelected]="groupForm.controls['company'].value"
							[organization]="organization"
							(companyEmitter)="companySelected($event)"
						></autocomplete-companies>
					</div>

					<!-- Nome -->
					<div class="form-group col-lg-6">
						<ga-input
							[title]="'group-page.label.name' | transloco"
							[placeholder]="'group-page.placeholder.enter-name' | transloco"
							[tooltip]="'meter-page.tooltip.max-char-35' | transloco"
							[disabled]="groupForm.controls['label'].disabled"
							[required]="true"
							[showError]="groupForm.controls['label'].invalid && groupForm.controls['label'].touched"
							formControlName="label"
							name="label"
						></ga-input>
					</div>

					<!-- Fuso horário -->
					<div class="form-group col-lg-6">
						<ga-typeahead
							class="grow-field"
							[title]="'meter-page.timezone' | transloco"
							[placeholder]="'meter-page.timezone' | transloco"
							[list]="timezones"
							[limitTo]="8"
							[disabled]="groupForm.controls['timezone'].disabled"
							[required]="true"
							[tooltip]="'group-page.tooltip.timezone' | transloco"
							formControlName="timezone"
							name="timezone"
							[showError]="groupForm.controls['timezone'].invalid && groupForm.controls['timezone'].touched"
						></ga-typeahead>
					</div>

					<!-- Pontos de medição -->
					<div class="form-group col-lg-12">
						<ga-autocomplete-chips
							class="grow-field"
							[title]="'group-page.label.add-datasources' | transloco"
							[placeholder]="'meter-page.placeholder.new' | transloco"
							[options]="dataSources"
							[required]="true"
							[disabled]="groupForm.controls['dataSources'].disabled"
							formControlName="dataSources"
							name="dataSources"
							[showError]="groupForm.controls['dataSources'].invalid && groupForm.controls['dataSources'].touched"
						>
						</ga-autocomplete-chips>
					</div>
				</div>

				<div class="row col-lg-12">
					<!-- Permissões de acesso -->
					<div>
						<hr class="separator" />
						<h3 class="title">
							<span class="mr-5">{{ 'meter-page.label.access-permission' | transloco }}</span>
							<ga-helper
								[text]="'group-page.tooltip.access-permission' | transloco"
								[icon]="'fa-solid fa-circle-info'"
								[iconSize]="16"
								[iconColor]="'#A3A3A3'"
								[tooltipLocale]="'above'"
							></ga-helper>
						</h3>

						<div class="row">
							<div class="form-group col-lg-12">
								<ga-autocomplete-chips
									class="grow-field"
									[title]="'meter-page.label.user' | transloco"
									[placeholder]="'meter-page.placeholder.new' | transloco"
									[options]="organizationUsers"
									[disabled]="groupForm.controls['users'].disabled"
									formControlName="users"
									name="users"
								>
								</ga-autocomplete-chips>
							</div>
						</div>
					</div>

					<!-- Notas -->
					<div>
						<hr class="separator" />
						<h3 class="title">
							{{ 'meter-page.label.notes-label' | transloco }}
						</h3>
						<div class="row">
							<div class="form-group col-lg-12">
								<ga-editor
									[placeholder]="'meter-page.placeholder.group-notes' | transloco"
									[editorConfig]="editorConfig"
									[disabled]="groupForm.controls['description'].disabled"
									formControlName="description"
									name="description"
								></ga-editor>
							</div>
						</div>
					</div>

					<!----------- Botões --------------->
					<hr class="separator" />
					<div class="button-group flex-row">
						<div class="btn-toolbar" role="toolbar" style="text-align: center">
							<div class="btn-group" role="group">
								<button class="btn btn-default" type="button" (click)="cancel()">
									{{ 'appliance.btn.cancel' | transloco }}
								</button>
							</div>
							<div class="btn-group" role="group">
								<button class="btn btn-primary btn-color-ga" type="submit" [disabled]="!groupForm.valid || isSaving">
									{{ 'organization.btn.save' | transloco }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

<ng-template #changeOrgTemplate>
	<div class="org-template-content">
		<div class="template-message">
			<span class="bold">{{ 'meter-page.change-org.group-content-2' | transloco }}</span>
			<span>{{ 'meter-page.change-org.content-3' | transloco }}</span>
		</div>
		<mat-radio-group
			class="radio-group"
			color="primary"
			[(ngModel)]="cloneOrgType"
			(ngModelChange)="dialogDisableForm(false)"
			name="cloneOrgType"
		>
			<mat-radio-button value="new">
				{{ 'meter-page.change-org.group-option-1' | transloco }}
			</mat-radio-button>
			<mat-radio-button value="move">
				{{ 'meter-page.change-org.group-option-2' | transloco }}
			</mat-radio-button>
		</mat-radio-group>
	</div>
</ng-template>
