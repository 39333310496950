import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { NG_ROOT_SCOPE, NG_ROUTER, NG_STATE_PARAMS } from 'webapp/hybrid-helpers/ajs-upgraded-providers';
import { TagService } from '../../services/tag.service';
import { TopBarSearchService } from '../top-bar-search/top-bar-search.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Tag } from 'webapp/app/dashboard/registrations/tags/tag-details/tag.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

declare let $: JQueryStatic;

@Component({
	selector: 'tags-filter',
	templateUrl: './tags-filter.component.html',
	styleUrls: ['./tags-filter.component.scss'],
})
export class TagsFilterComponent implements OnInit {
	separatorKeysCodes: number[] = [ENTER, COMMA];
	filteredTags!: Observable<Tag[]>;
	selectedTags: Tag[] = [];
	tags: Tag[] = [];
	loaded = false;

	@ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

	tagCtrl = new FormControl();
	query = new FormControl();
	onDestroy$ = new Subject<void>();

	@ViewChild('auto') autocomplete!: ElementRef<HTMLInputElement>;

	constructor(
		@Inject(NG_ROUTER) private $state,
		@Inject(NG_STATE_PARAMS) private $stateParams,
		@Inject(NG_ROOT_SCOPE) private $rootScope,
		@Inject(DOCUMENT) private document: Document,
		private topBarSearch: TopBarSearchService,
		private tagService: TagService
	) {
		this.$rootScope.$stateParams = this.$stateParams;
		this.filteredTags = this.tagCtrl.valueChanges.pipe(
			startWith(null),
			map((tagName: string | null) =>
				tagName ? this._filter(tagName || '') : this.tags.filter((t) => !this.selectedTags.includes(t))
			)
		);
	}

	ngOnInit(): void {
		this.changeStateParams([]);
		this.watchStateParamsChanges();

		$('#search-input').on('keyup', function () {
			(<any>$('input[type="search"]')).val((<any>this).value).trigger('keyup');
		});
	}

	changeStateParams(tags: Tag[]) {
		const urlParams = {
			query: this.$stateParams.query,
			organizationId: this.$stateParams.organizationId,
			tags: tags.map((t) => t.label).join(','),
			tab: 'task-manager',
		};
		this.$state.transitionTo('main.economy', urlParams, {
			inherit: true,
			reload: false,
			notify: false,
		});
	}

	watchStateParamsChanges() {
		const scope = this.$rootScope;
		scope.$watchCollection('$stateParams', (newValue, oldValue) => {
			if (newValue.tags != oldValue.tags) {
				const oldTags = oldValue.tags ? oldValue.tags.split(',') : [];
				const newTags = newValue.tags ? newValue.tags.split(',') : [];
				const tagsToRemove = oldTags.filter((tag) => !newTags.includes(tag));
				const tagsToAdd = newTags.filter((tag) => !oldTags.includes(tag));
				if (tagsToRemove.length > 0) {
					tagsToRemove.forEach((tag) => {
						this.remove(this.tags.find((t) => t.label == tag));
					});
				}
				if (
					tagsToAdd.length > 0 &&
					tagsToAdd.filter((tag) => !this.selectedTags.find((t) => t.label == tag)).length > 0
				) {
					tagsToAdd.forEach((tag) => {
						const t = this.tags.find((t) => t.label == tag);
						if (t) {
							this.selectedTags.push(t);
							this.tagCtrl.setValue('');
							this.tagCtrl.setValue(null);
							this.changeStateParams(this.selectedTags);
						}
					});
				}
			}
			if (newValue.organizationId) {
				this.tagService
					.getTagsByOrganization({
						organization: this.$stateParams.organizationId,
					})
					.then((tags) => {
						this.tags = tags || [];
						this.loaded = true;
					});
			}
		});
	}

	remove(tag: Tag | undefined): void {
		if (tag) {
			const index = this.selectedTags.indexOf(tag);

			if (index >= 0) {
				this.selectedTags.splice(index, 1);
			}
			this.tagCtrl.setValue(' ');
			this.tagCtrl.setValue(null);
			this.changeStateParams(this.selectedTags);
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		this.selectedTags.push(event.option.value);
		this.tagInput.nativeElement.value = '';
		this.tagCtrl.setValue(null);
		this.changeStateParams(this.selectedTags);
	}

	private _filter(value: string): Tag[] {
		if (typeof value == 'string') {
			const filterValue = value.toLowerCase();

			return this.tags.filter(
				(tag) => tag.label.toLowerCase().includes(filterValue) && !this.selectedTags.includes(tag)
			);
		}
		return this.tags.slice();
	}

	ngAfterViewInit() {
		this.query.setValue(this.$stateParams.query);
		this.query.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe({
			next: this.doPageFiltering.bind(this),
		});
	}

	doPageFiltering(value: string) {
		/*
      Possivelmente não é necessário fazer a transição para atualizar
    */
		const urlParams = {
			query: value,
		};
		const transition = this.$state.current.name;
		this.$state.transitionTo(transition, urlParams, {
			inherit: true,
			reload: false,
			notify: false,
		});

		const hiddenInputFilter = $(this.document).find('input[type="search"]');
		if (hiddenInputFilter) {
			hiddenInputFilter.val(value).trigger('keyup');
		}

		this.topBarSearch.setQuery(value);
	}

	ngOnDestroy() {
		this.onDestroy$.next();
	}
}
