import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'webapp/environments/environment';

@Injectable({ providedIn: 'root' })
export class MeterService {
	constructor(private http: HttpClient) {}

	/**
	 ** meterType - "consumption", "solar_generation"
	 **/
	getMeters(params) {
		return this.http
			.get(`${environment.backendUrl}/api/meters`, { params: params })
			.toPromise()
			.then((res: any) => {
				return res?.meters;
			});
	}

	/**
	 ** meterType - "consumption", "solar_generation"
	 **/
	getDataSources(params) {
		params.type = 'meter';
		return this.http
			.get(`${environment.backendUrl}/api/datasources`, { params: params })
			.toPromise()
			.then((res: any) => {
				return res?.dataSources;
			});
	}

	updateSupervisionBoard(organizationId: number, dataSources: number[]) {
		return this.http.put(`${environment.backendUrl}/api/datasources/supervisor/${organizationId}`, { dataSources });
	}

	getMetersByUser(params) {
		return this.http
			.get(`${environment.backendUrl}/api/users/${params.userId}/meters`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res?.meters;
			});
	}

	getMeter(params) {
		return this.http
			.get(`${environment.backendUrl}/api/meters/` + params.meterId, {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res?.meter;
			});
	}

	/**
	 ** Get Meters Types
	 **/
	getMetersTypes(params: any = {}) {
		return this.http
			.get(`${environment.backendUrl}/api/meterstypes`, { params: params })
			.toPromise()
			.then((res: any) => {
				return res?.metersTypes;
			});
	}

	/**
	 ** Get Meter File
	 **/
	getMeterFiles(id) {
		return this.http
			.get(`${environment.backendUrl}/api/meter/id/${id}/files`)
			.toPromise()
			.then((res: any) => {
				return res;
			});
	}

	/**
	 ** Delete Meter File
	 **/
	deleteMeterFiles(file) {
		return this.http
			.delete(`${environment.backendUrl}/api/meter/id/${file.modelId}/files/${file.fileName}`)
			.toPromise()
			.then((res: any) => {
				return res;
			});
	}

	/**
	 ** Save meter
	 **/
	saveMeter(params) {
		if (params.id) {
			return this.http
				.put(`${environment.backendUrl}/api/meters/${params.id}`, params)
				.toPromise()
				.then((res: any) => res);
		} else {
			return this.http
				.post(`${environment.backendUrl}/api/meters`, params)
				.toPromise()
				.then((res: any) => res);
		}
	}

	getUsers(params) {
		return this.http
			.get(`${environment.backendUrl}/api/meters/${params.meterId}/users`, {
				params,
			})
			.toPromise()
			.then((res: any) => {
				return res?.users;
			});
	}

	/**
	 ** Save DeviceInstallation
	 **/
	saveDeviceInstallation(params) {
		if (params.id && params.meterId)
			return this.http
				.put(`${environment.backendUrl}/api/meters/${params.meterId}/device-installation/${params.id}`, params)
				.toPromise();
		return this.http
			.post(`${environment.backendUrl}/api/meters/${params.meterId}/device-installation/`, params)
			.toPromise();
	}

	getContracts(dataSourceId) {
		return this.http
			.get(`${environment.backendUrl}/api/datasources/${dataSourceId}/contracts`)
			.toPromise()
			.then((res: any) => {
				return res?.contracts;
			});
	}

	status(params) {
		return this.http
			.get(`${environment.backendUrl}/api/datasources/${params.meterId}/status`)
			.toPromise()
			.then((res: any) => {
				return res?.dataSource;
			});
	}

	async getMaxDemand(params) {
		return this.http
			.get(`${environment.backendUrl}/api/meters/${params.meterId}/powers`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res?.powers;
			});
	}

	async getGroupMaxDemand(groupId, params) {
		return this.http
			.get(`${environment.backendUrl}/api/groups/${groupId}/powers`, {
				params: params,
			})
			.toPromise()
			.then((res: any) => {
				return res?.powers;
			})
			.catch((error) => {
				console.error('Erro:', error.message);
			});
	}
}

export const ng2MeterService = {
	name: MeterService.name,
	def: downgradeInjectable(MeterService),
};
